<template>
  <v-container fluid>
    <v-snackbar
      content-class="centered"
      absolute
      top
      :timeout="3000"
      v-model="snackbar"
      >{{ snackbarText }}</v-snackbar
    >
    <v-row class="header-title-actions">
      <v-col cols="12" sm="6" md="6">
        <h1 class="page-title">Ofrendas</h1>
      </v-col>

      <v-col class="align-end" cols="12" sm="6" md="6">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value="date"
          persistent
          scrollable
          max-width="300px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="date"
              label="Filtrar por fecha"
              prepend-icon="fa-calendar-alt"
              readonly
              filled
              rounded
              hide-details
              :disabled="loading"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="date"
            range
            :title-date-format="title"
            scrollable
            :max="today"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-btn
                class="flex-grow-1 flex-shrink-0"
                rounded
                outlined
                color="primary"
                @click="modal = false"
                >Cancelar</v-btn
              >
              <v-btn
                class="flex-grow-1 flex-shrink-0 ml-2"
                rounded
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  updateDateFilter();
                "
              >
                Filtrar
              </v-btn>
            </v-row>
          </v-date-picker>
        </v-dialog>
        <!-- <v-btn
          color="buttons"
          v-if="userDoc.role == 'sa'"
          class="mr-5"
          @click="reportDialog = true"
          :loading="loading"
        >
          <v-icon left> fas fa-chart-pie </v-icon>
          {{ $tc("screens.report") }}
        </v-btn>

        <v-btn color="buttons" @click="showAddScreenDialog" :loading="loading">
          <v-icon left> fas fa-plus </v-icon>
          {{ $tc("screens.add") }}
        </v-btn> -->
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12" sm="12" md="12">
        <files-table
          @menuClick="handleMenuActions($event)"
          :items="transactions"
          :loading="loading"
          :headers="headers"
          :showCapture="true"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-if="captureDialog"
      persistent
      v-model="captureDialog"
      max-width="600px"
    >
      <capture
        :selectedItem="selectedItem"
        @success="handleCaptured"
        @cancel="captureDialog = false"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import { db } from "@/firebase";
import { mapActions, mapState } from "vuex";
import filesTable from "@/components/files-table";
import moment from "moment-timezone";
import capture from "./capture.vue";

export default {
  name: "Offerings",
  components: { filesTable, capture },
  data() {
    return {
      transactions: [],
      loading: true,
      captureDialog: false,
      selectedItem: null,
      snackbarText: "",
      snackbar: false,
      modal: null,
      date: [
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
        `${moment()
          .tz("America/Tegucigalpa")
          .subtract(6, "hours")
          .toISOString()
          .substr(0, 10)}`,
      ],
      headers: [
        {
          text: "Estado",
          value: "status",
          width: "150px",
        },
        {
          text: "Código",
          value: "code",
          width: "150px",
        },
        {
          text: "Monto",
          value: "amount",
          width: "150px",
        },

        {
          text: this.$tc("headers.createdAt"),
          value: "createdAt",
          width: "200px",
        },
        {
          value: "options",
          sortable: false,
          align: "end",
          width: "180px",
        },
      ],
    };
  },

  computed: {
    ...mapState(["groupId", "group", "userDoc"]),
  },

  watch: {
    date(val) {
      if (val.length > 1) {
        this.getData();
      }
    },
  },
  methods: {
    ...mapActions(["cleanSearch"]),
    handleCaptured() {
      this.snackbarText = "Transacción capturada exitosamente.";
      this.snackbar = true;
      this.captureDialog = false;
    },
    handleMenuActions(e) {
      console.log(e);

      this.selectedItem = e.item;

      switch (e.action) {
        case "capture":
          this.captureDialog = true;
          break;

        default:
          break;
      }
    },
    updateDateFilter() {
      if (this.date.length > 1) {
        if (moment(this.date[0], "YYYY-MM-DD") > new Date(this.date[1])) {
          this.date = [this.date[1], this.date[0]];
        }
      } else {
        this.date = [this.date[0], this.date[0]];
      }
    },

    async getData() {
      await this.$binding(
        "transactions",
        db
          .collection("offerings")
          .where("groupId", "==", this.groupId)
          .where(
            "createdAt",
            ">",
            moment(this.date[0], "YYYY-MM-DD").startOf("day").toDate()
          )
          .where(
            "createdAt",
            "<",
            moment(this.date[1], "YYYY-MM-DD").endOf("day").toDate()
          )
      );
      this.loading = false;
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>