<template>
  <v-card :loading="loading" class="d-flex flex-column">
    <v-card-title class="grid-close">
      <span class="headline">Capturar transacción</span>
      <v-btn
        style="right: 10px; top: 10px; border-radius: 30px !important"
        icon
        color="buttons"
        fab
        absolute
        @click="$emit('cancel')"
        v-if="!loading"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-snackbar
        content-class="centered"
        top
        :timeout="3000"
        v-model="snackbar"
        >{{ snackbarText }}</v-snackbar
      >

      <p class="mt-5">
        ¿Está seguro que desea capturar la transacción con ID
        <b>{{ selectedItem.code }} </b> por un monto de
        <b> {{ selectedItem.amount | currency }}</b
        >?
      </p>
    </v-card-text>
    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              :loading="loading"
              large
              @click="$emit('cancel')"
              rounded
              :elevation="0"
              class="flex-grow-1 flex-shrink-0 white--text"
              :minHeight="56"
              block
              color="#6f6d6d"
            >
              Cancelar
            </v-btn></v-col
          >

          <v-col cols="12" sm="6" md="6">
            <v-btn
              :loading="loading"
              large
              @click="capture"
              rounded
              :elevation="0"
              class="flex-grow-1 flex-shrink-0 white--text"
              :minHeight="56"
              block
              color="primary"
            >
              {{ $tc("confirm.confirm_button") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/firebase";

export default {
  name: "capture",
  props: ["selectedItem"],
  data() {
    return {
      loading: false,
      snackbarText: "",
      snackbar: false,
    };
  },

  filters: {
    currency: function (amount) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HNL",
        minimumFractionDigits: 2,
      });
      return formatter.format(amount);
    },
  },

  computed: {
    ...mapState(["userDoc"]),
  },

  methods: {
    capture() {
      this.loading = true;

      db.collection("offerings")
        .doc(this.selectedItem[".key"])
        .update({
          status: "captured",
          capturedBy: this.userDoc[".key"],
          capturedAt: new Date(),
        })
        .then((r) => {
          this.$emit("success");
        })
        .catch((e) => {
          console.log(e);
          this.snackbarText = e.message;
          this.snackbar = true;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
p {
  font-size: 18px !important;
}
</style>